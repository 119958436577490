<template>
  <el-card :title="form.id ? '编辑业务' : '新增业务'">
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="150px"
    >
      <b-row>
        <b-col md="6">
          <el-form-item
            label="场景名称"
            prop="name"
            :rules="[ { required: true, message: '业务名称不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.name"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="场景类型"
            prop="sceneType"
            :rules="[ { required: true, message: '场景类型不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.sceneType"
              placeholder="请选择场景类型"
            >
              <el-option
                v-for="(item,index) in [{label:'基本业务场景',value:1},{label:'拓展业务场景',value:2}]"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="场景描述">
            <el-input
              v-model="form.describe"
              type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="服务的年龄段">
            <b-row>
              <b-col md="5">
                <el-input
                  v-model.number="form.userBeginAge"
                  type="number"
                />
              </b-col>
              至
              <b-col md="6">
                <el-input
                  v-model.number="form.userEndAge"
                  type="number"
                />
              </b-col>
            </b-row>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="服务地区">
            <el-input
              v-model="form.serviceArea"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="上线时间">
            <el-date-picker
              v-model="form.uptime"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="是否用于隐私声明"
          >
            <el-radio
              v-model="form.isUseStatement"
              :label="true"
            >
              是
            </el-radio>
            <el-radio
              v-model="form.isUseStatement"
              :label="false"
            >
              否
            </el-radio>
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="信息保护措施">
            <el-input
              v-model="form.informationProtectionMeasures"
              type="textarea"
            />
          </el-form-item>
        </b-col>
<!--        <h3 style="padding-left: 20px">-->
<!--          个人信息主体权力-->
<!--        </h3>-->
<!--        <b-col md="12">-->
<!--          <el-form-item label="访问权力实现">-->
<!--            <el-input-->
<!--              v-model="form.userAccessRights"-->
<!--              type="textarea"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </b-col>-->
<!--        <b-col md="12">-->
<!--          <el-form-item label="修改权力实现">-->
<!--            <el-input-->
<!--              v-model="form.userDeleteRights"-->
<!--              type="textarea"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </b-col>-->
<!--        <b-col md="12">-->
<!--          <el-form-item label="删除权力实现">-->
<!--            <el-input-->
<!--              v-model="form.userUpdateRights"-->
<!--              type="textarea"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </b-col>-->
<!--        <b-col md="12">-->
<!--          <el-form-item label="获取个人信息副本权力实现">-->
<!--            <el-input-->
<!--              v-model="form.userGetRights"-->
<!--              type="textarea"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </b-col>-->
<!--        <b-col md="12">-->
<!--          <el-form-item label="限制处理权力实现">-->
<!--            <el-input-->
<!--              v-model="form.userLimitHandleRights"-->
<!--              type="textarea"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </b-col>-->
<!--        <b-col md="12">-->
<!--          <el-form-item label="反对处理权力实现">-->
<!--            <el-input-->
<!--              v-model="form.userAntiHandleRights"-->
<!--              type="textarea"-->
<!--            />-->
<!--          </el-form-item>-->
<!--        </b-col>-->
      </b-row>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="back">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-card>
</template>

<script>
import { saveOrUpdateScene, getSceneById } from '@/api/enterprise/product'
import {
  success, error,
} from '@core/utils/utils'

export default {
  data() {
    return {
      loading: false,
      form: {
        name: '',
        describe: '',
        serviceArea: '',
        informationProtectionMeasures: '',
        isEnforce: true,
        isUseStatement: true,
        sceneType: '',
        uptime: '',
        userAccessRights: '',
        userAntiHandleRights: '',
        userBeginAge: undefined,
        userDeleteRights: '',
        userEndAge: undefined,
        userGetRights: '',
        userLimitHandleRights: '',
        userUpdateRights: '',
        id: 0,
      },
      productId: Number(this.$route.query.pId),
      id: this.$route.query.id,
    }
  },
  created() {
    if (this.id) {
      this.form.id = Number(this.id)
      this.getDetail()
    }
  },
  mounted() {
    this.navActiveInit(1, '业务管理', '企业管理')
  },
  destroyed() {
    this.navActiveInit(2, '业务管理', '企业管理', '/product')
  },
  methods: {
    back() {
      this.$router.push({
        path: '/product-scene',
        query: {
          id: this.productId,
        },
      })
    },
    getDetail() {
      getSceneById(this.productId, this.form.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          this.form.userBeginAge = this.form.userBeginAge !== 0 ? this.form.userBeginAge : undefined
          this.form.userEndAge = this.form.userEndAge !== 0 ? this.form.userEndAge : undefined
          if (this.form.onlineTime) {
            this.form.onlineTime = this.form.onlineTime.substr(0, 10)
          }
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (!this.form.id) {
            delete this.form.id
          }
          if (this.form.onlineTime) {
            this.form.onlineTime = `${this.form.onlineTime} 00:00:00`
          }

          saveOrUpdateScene(this.productId, this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
.form-item-flex .el-form-item__content {
  display: flex;
}
</style>
